import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = [ "output" ]

  initFilters(event) {
    var selects = document.querySelectorAll('.mdc-select');
    
    [].forEach.call(selects, function(select) {
      
      var input = select.getElementsByTagName("INPUT")[0];
      var selectMDC = select.MDCSelect;

      selectMDC.listen('MDCSelect:change', () => {
        var url = new URL(window.location);

        url.searchParams.set(input.id, selectMDC.value);
        window.location = url.href;
      });
    });

  }
}
