require("@rails/ujs").start()
require("@rails/activestorage").start()
require("packs/material")
require("flatpickr")

import "controllers"
import "magnific-popup"
import "trumbowyg"
import "../styles/media"


$(document).ready(function() {

  $("header .nav_button").on("click", function () {
    profileHide();
    if ($(this).hasClass("active") == true) {
      navHide();
    } else {
      navShow();
    }
  });

  $("header .arrow").on("click", function () {
    navHide();

    if ($(this).hasClass("active") == true) {
      profileHide();
    } else {
      profileShow();
    }
  });

  initMagnificPopup();
});

function navHide() {
  $("header .nav_button").removeClass("active");
  $("header .nav").removeClass("active");
}

function navShow() {
  $("header .nav_button").addClass("active");
  $("header .nav").addClass("active");
}
  
function profileHide() {
  $("header .arrow").removeClass("active");
  $("header .arrow ul").removeClass("active");
}

function profileShow() {
  $("header .arrow").addClass("active");
  $("header .arrow ul").addClass("active");
}

function  initMagnificPopup() {
  if ($('.popup_link')[0] !== undefined) {

    $('.popup_link').magnificPopup({
      type: 'ajax',
      focus: '.white-popup input',
      alignTop: true,
      callbacks: {
        open: function() {
        }
      }
    });

    $('.popup_link.close_confirm').magnificPopup({
      type: 'ajax',
      alignTop: true,
      focus: '.white-popup input',
      callbacks: {
        open: function() {
          $.magnificPopup.instance.close = function() {

            if ($.magnificPopup.instance.content && $.magnificPopup.instance.content.hasClass("close_with_confirmation")) {
              var conf_text = $.magnificPopup.instance.content.find(".magnific-close-confirm").html();
              if (conf_text !== undefined) {
                var confirmed = confirm(conf_text);
              }
              
              if(!confirmed) {
                return;
              }
              
            } 

            $.magnificPopup.proto.close.call(this);

            location.reload();
          };

        }
      }
    });

  }
}