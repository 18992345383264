import { Controller } from "stimulus"
import $ from 'jquery';
import { DataSet, Timeline } from "vis-timeline/standalone";

var timeline = null;

export default class extends Controller {
  static targets = [ "chart", "visualization" ]


  connect () {
    let _this = this,
        filter = "";

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    filter = urlParams.get('my');
    
    fetch("/en/calendar.json?f=" + filter)
    .then(data => {return data.json();})
    .then(json => {
     
      // Create a DataSet (allows two way data-binding)
      var items = new DataSet(json.chart);

      // Configuration for the Timeline
      var options = {
        start: new Date("2023-10-28T00:00:00"), 
        end: new Date("2023-11-05T00:00:00"), 
        max: new Date("2023-11-07T00:00:00"), 
        min: new Date("2023-10-28T00:00:00"), 
        zoomable: false,
        maxHeight: 800,
        minHeight: 200,
        zoomMin: 10000000,
        xss: {
          disabled: true,
        },
      };
      // Create a Timeline
      timeline = new Timeline(_this.visualizationTarget, items, options);

      timeline.on('select', function (properties) {
        console.log('selected event: ' + properties.items);
      });
    });
  }

  zoomIn(event) {
    timeline.zoomIn(0.3);
  }

  zoomOut(event) {
    timeline.zoomOut(0.3);
  }

}
