import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output", "button", "input"]

  connect() {
  }
  openUploader(event) {
    this.inputTarget.click();
  }

  getFileInfo(event) {
    var file = event.currentTarget.files[0];
    var name = file.name

    if (name.length > 30) name = name.substring(0, 27) + "... ";
    this.outputTarget.innerHTML = name + " (" + this.toKb(file.size) + "Kb)";
  }

  toKb(num) {
    var result = num/1024;
    result = (Math.round(result * 100) / 100)
    return result;
  } 

} 
