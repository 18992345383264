import { Controller } from "stimulus";
import 'trumbowyg'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload.min.js';

export default class extends Controller {
  static targets = [ "editor" ]


  connect() {
    var root_path = window.location.protocol + '//' + window.location.host;

    var trumbowyg_options = {
      btnsDef: {
          // Create a new dropdown
          image: {
              dropdown: ['insertImage', 'upload'],
              ico: 'insertImage'
          },
          orderlist: {
            dropdown: ['unorderedList', 'orderedList'],
            ico: 'orderedList'
          }
      },
      btns: [
        ['undo', 'redo'],
        ['strong', 'em', 'del'],
        ['link'],
        ['image'],
        ['noembed'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['orderlist'],
        ['removeformat']
      ],
      autogrow: true,
      removeformatPasted: true,
      urlProtocol: true,
      minimalLinks: true,
      imageWidthModalEdit: true,
      svgPath: "/trumbowyg/icons.svg",
      plugins: {
          upload: {
            serverPath:  root_path + "/images",
            fileFieldName: 'image[file]',
            //urlPropertyName: 'data.link',
          }
      }
    };

    $("#" + this.editorTarget.id).trumbowyg(trumbowyg_options);
  }

}
