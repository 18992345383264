import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "ageoutput", "targetoutput" ]


  setTargetGroup(event) {
    let groups = [];
    let _this = this;
    
    setTimeout(function() {
      $(".mdc-chip-set.target_groups .mdc-chip--selected").each(function( index ) {
        groups.push($( this ).data("group"));
      });

      _this.targetoutputTarget.value = groups;
    }, 200);
  }

  setAgeGroup(event) {
    let groups = [];
    let _this = this;
    
    setTimeout(function() {
      $(".mdc-chip-set.age_groups .mdc-chip--selected").each(function( index ) {
        groups.push($( this ).data("group"));
      });

      _this.ageoutputTarget.value = groups;
    }, 200);
  }

  setOnlineTool(event) {
    let tools = [];
    let _this = this;
    
    setTimeout(function() {
      $(".mdc-chip-set.online_tools .mdc-chip--selected").each(function( index ) {
        tools.push($( this ).data("tool"));
      });

      _this.targetoutputTarget.value = tools;
    }, 200);
  }

  showCategoryDescription(event) {
    let cat = event.currentTarget.dataset.value;
    cat = cat.split(" ")[0]
    
    $(".tooltip").hide();
    $(".tooltip." + cat).show();
  }

  switchOnlineParticipation(event) {
    setTimeout(function () { 
        if ($("#online-participatable-switch").attr("aria-checked") == "false") {
          $("#online-participation-fields").hide();
        } else {
          $("#online-participation-fields").show();
        }
      }, 200);
  }

  switchOfflineParticipation(event) {
    setTimeout(function () { 
        if ($("#offline-participatable-switch").attr("aria-checked") == "false") {
          $("#offline-participation-fields").hide();
        } else {
          $("#offline-participation-fields").show();
        }
      }, 200);
  }

  switchOnlineTooltip(event) {
    setTimeout(function () { 
        if ($("#online-limited-switch").attr("aria-checked") == "false") {
          $(".tooltip.open").show();
          $(".tooltip.not_open").hide();
          $("#online_participants_size").hide();
        } else {
          $(".tooltip.open").hide();
          $(".tooltip.not_open").show();
          $("#online_participants_size").show();
        }
      }, 200);
  }

  switchOfflineTooltip(event) {
    setTimeout(function () { 
        if ($("#offline-limited-switch").attr("aria-checked") == "false") {
          $(".tooltip.open").show();
          $(".tooltip.not_open").hide();
          $("#offline_participants_size").hide();
        } else {
          $(".tooltip.open").hide();
          $(".tooltip.not_open").show();
          $("#offline_participants_size").show();
        }
      }, 200);
  }

  switchAgeGroup(event) {
    if ($(".age_group_for_learners").hasClass("hidden") == true) {
      $(".age_group_for_learners").removeClass("hidden");
    } else {
      $(".age_group_for_learners").addClass("hidden");
    }
  }

  filterParticipations(event) {
    let filter = "";
    filter = event.currentTarget.dataset.filter;

    $(".participations .filters .mdc-button").addClass("grey-text");
    event.currentTarget.classList.remove("grey-text");

    if (filter == "online" || filter == "offline") {
      $(".participations .participant").hide();
      $(".participations .participant." + filter).show();
    } else {
      $(".participations .participant").show();
    }

  }
}
