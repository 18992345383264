import mdcAutoInit from '@material/auto-init';
window.mdcAutoInit = mdcAutoInit;

import {MDCRipple} from '@material/ripple';
mdcAutoInit.register('MDCRipple', MDCRipple);

import {MDCTextField} from '@material/textfield';
mdcAutoInit.register('MDCTextField', MDCTextField);

import {MDCTabBar} from '@material/tab-bar';
mdcAutoInit.register('MDCTabBar', MDCTabBar);

import {MDCList} from '@material/list';
mdcAutoInit.register('MDCList', MDCList);

import {MDCDrawer} from "@material/drawer";
mdcAutoInit.register('MDCDrawer', MDCDrawer);

import {MDCChipSet} from '@material/chips';
mdcAutoInit.register('MDCChipSet', MDCChipSet);

import {MDCSelect} from '@material/select';
mdcAutoInit.register('MDCSelect', MDCSelect);

import {MDCTopAppBar} from '@material/top-app-bar';
mdcAutoInit.register('MDCTopAppBar', MDCTopAppBar);

import {MDCSwitch} from '@material/switch';
mdcAutoInit.register('MDCSwitch', MDCSwitch);

import {MDCFormField} from '@material/form-field';
mdcAutoInit.register('MDCFormField', MDCFormField);

import {MDCCheckbox} from '@material/checkbox';
mdcAutoInit.register('MDCCheckbox', MDCCheckbox);

import {MDCNotchedOutline} from '@material/notched-outline';
mdcAutoInit.register('MDCNotchedOutline', MDCNotchedOutline);

import {MDCSnackbar} from '@material/snackbar';
mdcAutoInit.register('MDCSnackbar', MDCSnackbar);

import {MDCLinearProgress} from '@material/linear-progress';
mdcAutoInit.register('MDCLinearProgress', MDCLinearProgress);

window.mdcAutoInit = function () {
  mdcAutoInit();
};

document.addEventListener("MDCAutoInit:End", () => {
  const selectElements = document.querySelectorAll('.mdc-select');  
  selectElements.forEach((selectEl) => {
    if (selectEl.getAttribute('listener4Input') !== 'true') {
      let select = selectEl.MDCSelect;

      let initSelectInput = function(e) {
        if (e.target.querySelector('input[type=hidden]') !== null)
          e.target.querySelector('input[type=hidden]').value = select.value;
      }

      select.listen('MDCSelect:change', initSelectInput);
      console.log("listener4Input", "ADDED");
      selectEl.setAttribute('listener4Input', 'true');
    }

  });
});

window.addEventListener('DOMContentLoaded', (event) => {
  //NOTE: => javascript_pack_tag 'admin'  schould be WITHOUT  'async: true' to be able to fire DOMContentLoaded from JS
  window.mdcAutoInit();
});