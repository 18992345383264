import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = ["sid", "lat", "lon", "addr", "country", "city", "code", "address" ]

  connect() {
    let _this = this;

    window.mdcAutoInit();
    _this.initMagnificPopup();


    $(".mdc-steps .mdc-button").on("click", function (e) {
      $("#next_step").val($(this).attr('id'));
      $(".go_next").click();
    });

    $(".go_prev").on("click", function () {
      let st = _this.data.get("step");
      if (st > 0) {
        $("#next_step").val(_this.data.get("step") - 1);
      }
    });

    $("form.edit_event").on("ajax:success", function (e) {
      let [data, status, xhr] = e.detail;
      //alert("#step_" + $("#next_step").val());
      $("#step_" + $("#next_step").val())[0].click();
    });

    $('input[type=file]').change(function() {
      var file = $('input[type=file]')[0].files[0];
      var name = file.name

      if (name.length > 30) name = name.substring(0, 27) + "... ";
      $(".upload_info").html(name + " (" + toKb(file.size) + "Kb)");
    });

    $("#event_canceled").on("change", function () {
      if ($("#event_canceled").val() == "true") {
        $(".cancel_warninng").show(200);
      } else {
        $(".cancel_warninng").hide();
      }
    });
    
    const selects = document.querySelectorAll('.mdc-select');

    for (const select of selects) {
      select.addEventListener('MDCSelect:change', e => {
        select.querySelector("input").value = e.detail.value;
      });
    }

    var input = document.getElementById('gsearch');

    if ((typeof google === 'undefined') || (typeof google.maps === 'undefined')) {
      $.getScript("https://maps.googleapis.com/maps/api/js?v=3.0&libraries=places&language=en&key=AIzaSyDmeD92wg9vYQeBU8maSJGedwWMuHLbOvE", function() {
        if (input != undefined)
          _this.setAddress(_this);
      });
    } else {
      if (input != undefined)
        _this.setAddress(_this);
    }

    //Date picker
    flatpickr('#event_starts_at, #event_ends_at', {
      enableTime: true,
      dateFormat: "d-m-Y H:i",
      minDate: "30-10-2023 00:00",
      maxDate: "03-11-2023 23:59",
      time_24hr: true
    });

    $("input").keypress(
      function(event){
        if (event.which == '13') {
          event.preventDefault();
        }
    });
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  setAddress(l_this) {
    var input = document.getElementById('gsearch');
    var searchBox = new google.maps.places.SearchBox(input);
    var componentForm = {
          street_number: 'short_name',
          route: 'long_name',
          locality: 'long_name',
          administrative_area_level_3: 'short_name',
          country: 'long_name',
          postal_code: 'short_name'
        };

    searchBox.addListener('places_changed', function() {
      var places = searchBox.getPlaces();

      var lon, lat, addr, city, country, country_code;
      

      if (places.length == 0) {
        return;
      }

      places.forEach(function(place) {
        var street_number = "";
        var route = "";

        for (var i = 0; i < place.address_components.length; i++) {
          var addressType = place.address_components[i].types[0];

          if (componentForm[addressType]) {
            var val = place.address_components[i][componentForm[addressType]];
            
            if (addressType == "country") {
              country = val;
              country_code = place.address_components[i]["short_name"];
            } else if (addressType == "locality") {
              city = val;
            } else if (addressType == "administrative_area_level_3" && city == null) {
              city = val;
            } else if (addressType == "route") {
              route = val;
            } else if (addressType == "street_number") {
              street_number = val;
            } 
          }
          addr = route + " " + street_number;
        }

        lat = place.geometry.location.lat();
        lon = place.geometry.location.lng();
      });
        
      l_this.setAddressInputs(lon, lat, addr, city, country, country_code)
      
    });
  }

  setAddressInputs(lon, lat, addr, city, country, country_code) {
    this.lonTarget.value = lon;
    this.latTarget.value = lat;
    this.addrTarget.value = addr;
    this.cityTarget.value = city;
    this.countryTarget.value = country;
    //this.codeTarget.value = country_code;

    //this.addressTarget.value = addr;

    $("#gsearch").parent().attr("data-mdc-auto-init-state", null);
    window.mdcAutoInit();

  }

  resetAddress(event) {
    if (this.addressTarget.value.length == 0) {
      this.lonTarget.value = null;
      this.latTarget.value = null;
      this.addrTarget.value = null;
      this.cityTarget.value = null;
      this.countryTarget.value = null;
      //this.codeTarget.value = null;
    } else {
      //this.addressTarget.value = this.countryTarget.value;
      //this.addressTarget.value = addr;
    }
  }

  openEdit(event) {
    $(".edit_event.mdc-button")[0].click();
  }

  closePopUp(event) {
    $.magnificPopup.proto.close.call(this);
  }

  initMagnificPopup() {
    $('.popup_link').magnificPopup({
      type: 'ajax',
      focus: '.white-popup input',
      alignTop: true,
      callbacks: {
        open: function() {
        }
      }
    });

    $('.popup_link.close_confirm').magnificPopup({
      type: 'ajax',
      alignTop: true,
      focus: '.white-popup input',
      callbacks: {
        open: function() {
          $.magnificPopup.instance.close = function() {

            if ($.magnificPopup.instance.content && $.magnificPopup.instance.content.hasClass("close_with_confirmation")) {
              var conf_text = $.magnificPopup.instance.content.find(".magnific-close-confirm").html();
              if (conf_text !== undefined) {
                var confirmed = confirm(conf_text);
              }
              
              if(!confirmed) {
                return;
              }
              
            } 

            $.magnificPopup.proto.close.call(this);

            location.reload();
          };

        }
      }
    });
  }
}