import { Controller } from "stimulus"
import $ from 'jquery';
import {MDCSelect} from '@material/select';

export default class extends Controller {
  static targets = [ "output" ]

  connect () {
    window.mdcAutoInit();
  }

}
